/* eslint-disable no-use-before-define */
import {login, register} from '../utils/WordPress'
import {analyticsRegister, analyticsLogin} from '../services/GoogleAnalytics'
import {pixelLogin, pixelCompleteRegistration} from '../services/FacebookPixel'

export const isBrowser = () => typeof window !== 'undefined'

export const getUser = () => {
  if (isBrowser() && window.localStorage.getItem('wcUser')) {
    return JSON.parse(window.localStorage.getItem('wcUser'))
  }
  return {}
}

export const userRegister = (
  email,
  password,
  first_name = '',
  last_name = '',
  bday = '',
  setLoading = () => {},
  setApiError = () => {},
  callback = () => {},
) => {
  setLoading(true)

  register(email, password, first_name, last_name, bday)
    .then(responseData => {
      if (responseData.data && responseData.data.status >= 400) {
        let errorMessage = 'Something went wrong while trying to register'
        console.log('errorMessage: ', responseData.data)
        setApiError({header: 'Registration invalid', content: errorMessage})
      } else {
        setApiError(false)
        analyticsRegister()
        pixelCompleteRegistration()
      }
      callback()
    })
    .catch(async error => {
      console.log('Exception while registering: ', error)
      let errorString = {
        header: 'Register failed',
        content: 'Register failed, please try again!',
      }
      if (error.message) {
        errorString.content = error.message
      } else {
        console.log('Error Response: ', error)
      }
      setApiError(errorString)
      setLoading(false)
    })
}

export const userLogin = (
  email,
  password,
  setLoading,
  setApiError,
  setLoggedInState,
  afterCallback = () => {},
) => {
  setLoading(true)
  login(email, password)
    .then(responseData => {
      if (responseData.data && responseData.data.status >= 400) {
        let errorMessage = ''
        if (responseData.code.includes('incorrect_password')) {
          errorMessage =
            responseData.message.split(' <a')[0] +
            ' If you have forgotten your password, please reset your password below.'
        } else if (responseData.code.includes('invalid_email')) {
          errorMessage =
            responseData.message.split(' <a')[0] +
            ' This email is not registered. Please register your account <a href="/register/">here</a>.'
        }
        setApiError({header: 'Invalid login', content: errorMessage})
        setLoggedInState(false)
      } else {
        // eslint-disable-next-line no-use-before-define
        setUser(responseData)
        setApiError(false)
        setLoggedInState(true)
        analyticsLogin()
        pixelLogin()
      }
      setLoading(false)
      afterCallback()
    })
    .catch(error => {
      console.log('Exception while logging in: ', error)
      setApiError({
        header: 'Login failed',
        content:
          'Login is unavailable at this time. Please come back later and try again!',
      })
      setLoading(false)
      setLoggedInState(false)
    })
}

export const setUser = user => {
  if (user.token && user.token.includes('.')) {
    // Decode the JWT webtoken from base64
    const tokenPayload = JSON.parse(atob(user.token.split('.')[1]))
    const localStorageUser = {...user, tokenPayload}
    if (isBrowser())
      window.localStorage.setItem('wcUser', JSON.stringify(localStorageUser))
  } else {
    console.log('Login token format invalid: ', user)
    // eslint-disable-next-line no-throw-literal
    throw 'Login token format invalid'
  }
}

export const isLoggedIn = () => {
  const user = getUser()
  let expires = null

  // Check if our token is still valid
  if (isBrowser() && user.token && user.tokenPayload) {
    expires = user.tokenPayload.exp
  }

  if (!expires) {
    return false
  }

  if (expires < Math.floor(new Date() / 1000)) {
    logout()
    return false
  }

  return !!user.token
}

export const logout = () => {
  if (isBrowser()) window.localStorage.setItem('wcUser', JSON.stringify({}))
}
