import React from 'react'
import {Helmet} from 'react-helmet'
import {format, addMonths} from 'date-fns'

const isBrowser = () => typeof window !== 'undefined'

// GOOGLE TAG -- runs on every page to let google keep track of conversions
export const GoogleTagAllPages = () => {
  return (
    <Helmet>
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.GATSBY_GTAG_ACCOUNT_ID}`}
      ></script>
      <script>
        {`window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
      
        gtag('set', {
          'country': 'ZA',
          'currency': 'ZAR'
        });
        gtag('config', '${process.env.GATSBY_GTAG_ACCOUNT_ID}');`}
      </script>
    </Helmet>
  )
}

// GOOGLE ANALYTICS --- Select Product Event
export const analyticsSelect = item => {
  try {
    if (isBrowser() && window.gtag) {
      // Separate this into parent/size data?
      const itemStruct = {
        id: item.sku,
        name: item.name,
        price: item.price,
        category: item.categories,
      }
      const eventParams = {
        event_category: 'Product',
        event_label: itemStruct.name,
        content_type: 'product',
        items: [itemStruct],
      }
      if (process.env.GATSBY_GTAG_DEBUG == 'true')
        console.log('gtag event: view_item -> eventParams = ', eventParams)
      window.gtag('event', 'view_item', eventParams)
    }
  } catch {
    if (process.env.GATSBY_GTAG_DEBUG == 'true')
      console.log(
        'gtag event: Could not run Google Analytics function "Select Product Event".',
      )
  }
}

// GOOGLE ANALYTICS --- Add Items To Cart Event
export const analyticsAddToCart = items => {
  try {
    if (isBrowser() && window.gtag) {
      const itemsArray = items.map(item => {
        return {
          id: item.id,
          name: item.name,
          quantity: item.quantity,
          price: item.price,
          category: item.categories
            .filter(cat => !cat.name.startsWith('All '))
            .map(cat => cat.name)
            .join('/'),
        }
      })
      const eventParams = {
        event_category: 'Cart',
        event_label: itemsArray[0].name,
        content_type: 'product',
        items: itemsArray,
      }
      if (process.env.GATSBY_GTAG_DEBUG == 'true')
        console.log('gtag event: add_to_cart -> eventParams = ', eventParams)
      window.gtag('event', 'add_to_cart', eventParams)
    }
  } catch (e) {
    if (process.env.GATSBY_GTAG_DEBUG == 'true')
      console.log(
        'gtag event: Could not run Google Analytics function "Add Items To Cart Event".',
      )
  }
}

// GOOGLE ANALYTICS --- Remove Items From Cart Event
export const analyticsRemoveFromCart = item => {
  try {
    if (isBrowser() && window.gtag) {
      const itemsStruct = [
        {
          id: item.id,
          name: item.name,
          quantity: item.quantity,
          price: item.price,
          category: item.categories
            .filter(cat => !cat.name.startsWith('All '))
            .map(cat => cat.name)
            .join('/'),
        },
      ]
      const eventParams = {
        event_category: 'Cart',
        event_label: item.name,
        content_type: 'product',
        items: itemsStruct,
      }
      if (process.env.GATSBY_GTAG_DEBUG == 'true')
        console.log(
          'gtag event: remove_from_cart -> eventParams = ',
          eventParams,
        )
      window.gtag('event', 'remove_from_cart', eventParams)
    }
  } catch {
    console.log(
      'gtag event: Could not run Google Analytics function "Remove Items From Cart Event".',
    )
  }
}

// GOOGLE ANALYTICS --- Go to Cart
export const analyticsGoToCart = data => {
  try {
    if (isBrowser() && window.gtag) {
      const itemsStruct = data.map(item => {
        return {
          id: item.sku,
          name: item.product_name,
          quantity: item.quantity,
          price: item.product_price,
          category: item.categories
            .filter(cat => !cat.name.startsWith('All '))
            .map(cat => cat.name)
            .join('/'),
        }
      })
      const eventParams = {
        event_category: 'Checkout',
        event_label: 'Go to Cart Page',
        content_type: 'product',
        items: itemsStruct,
        checkout_option: 'Go to Cart Page',
        checkout_step: 1,
      }
      if (process.env.GATSBY_GTAG_DEBUG == 'true')
        console.log(
          'gtag event: begin_checkout (View Cart) -> eventParams = ',
          eventParams,
        )
      window.gtag('event', 'begin_checkout', eventParams)
    }
  } catch {
    if (process.env.GATSBY_GTAG_DEBUG == 'true')
      console.log(
        'gtag event: Could not run Google Analytics function "Go To Cart".',
      )
  }
}

// GOOGLE ANALYTICS --- Shipping Cart Step
export const analyticsCartStepShipping = data => {
  try {
    if (isBrowser() && window.gtag) {
      const itemsStruct = data.map(item => ({
        id: item.sku,
        name: item.product_name,
        quantity: item.quantity,
        price: item.product_price,
        category: item.categories
          .filter(cat => !cat.name.startsWith('All '))
          .map(cat => cat.name)
          .join('/'),
      }))
      const eventParams = {
        event_category: 'Checkout',
        event_label: 'Provide Delivery Information',
        content_type: 'product',
        items: itemsStruct,
        checkout_option: 'Provide Delivery Information',
        checkout_step: 2,
      }
      if (process.env.GATSBY_GTAG_DEBUG == 'true')
        console.log(
          'gtag event: checkout_progress 2 (Provide Delivery Information) -> eventParams = ',
          eventParams,
        )
      window.gtag('event', 'checkout_progress', eventParams)
    }
  } catch {
    if (process.env.GATSBY_GTAG_DEBUG == 'true')
      console.log(
        'gtag event: Could not run Google Analytics function "Shipping Cart Step".',
      )
  }
}

// GOOGLE ANALYTICS --- Payment Cart Step
export const analyticsCartStepPayment = data => {
  try {
    if (isBrowser() && window.gtag) {
      const itemsStruct = data.map(item => ({
        id: item.sku,
        name: item.product_name,
        quantity: item.quantity,
        price: item.product_price,
        category: item.categories
          .filter(cat => !cat.name.startsWith('All '))
          .map(cat => cat.name)
          .join('/'),
      }))
      const eventParams = {
        event_category: 'Checkout',
        event_label: 'View Payment Methods',
        content_type: 'product',
        items: itemsStruct,
        checkout_option: 'View Payment Methods',
        checkout_step: 3,
      }
      if (process.env.GATSBY_GTAG_DEBUG == 'true')
        console.log(
          'gtag event: checkout_progress 3 (View Payment Methods) -> eventParams = ',
          eventParams,
        )
      window.gtag('event', 'checkout_progress', eventParams)
    }
  } catch {
    if (process.env.GATSBY_GTAG_DEBUG == 'true')
      console.log(
        'gtag event: Could not run Google Analytics function "Payment Cart Step".',
      )
  }
}

// GOOGLE ANALYTICS --- Paymend method credit card
export const analyticsPaymentMethod = paymentOption => {
  try {
    if (isBrowser() && window.gtag) {
      const eventParams = {
        event_category: 'Checkout',
        event_label: 'Select Payment Option - ' + paymentOption,
        checkout_step: 3,
        checkout_option: paymentOption,
      }
      if (process.env.GATSBY_GTAG_DEBUG == 'true')
        console.log(
          'gtag event: set_checkout_option 3 (Select Payment Option) -> eventParams = ',
          eventParams,
        )
      window.gtag('event', 'set_checkout_option', eventParams)
    }
  } catch {
    if (process.env.GATSBY_GTAG_DEBUG == 'true')
      console.log(
        'gtag event: Could not run Google Analytics function "Payment Method Credit Card".',
      )
  }
}

// GOOGLE ANALYTICS --- Payment method credit card
export const analyticsPayNowOnOldOrder = orderNumber => {
  try {
    if (isBrowser() && window.gtag) {
      const eventParams = {
        event_category: 'Checkout',
        event_label: 'Old Order: Pay Now - ' + orderNumber,
        checkout_step: 3,
        checkout_option: 'pay_old_order',
      }
      if (process.env.GATSBY_GTAG_DEBUG == 'true')
        console.log(
          'gtag event: set_checkout_option 3 (Old Order: Pay Now) -> eventParams = ',
          eventParams,
        )
      window.gtag('event', 'set_checkout_option', eventParams)
    }
  } catch {
    if (process.env.GATSBY_GTAG_DEBUG == 'true')
      console.log(
        'gtag event: Could not run Google Analytics function "Payment Method Credit Card".',
      )
  }
}

// GOOGLE ANALYTICS --- Purchase Event
export const analyticsPurchase = (paymentData, items) => {
  try {
    if (isBrowser() && window.gtag) {
      const itemsStruct = items.map((item, i) => ({
        id: item.sku,
        name: item.name,
        quantity: item.quantity,
        price: item.total / item.quantity,
        category: item.categories
          .filter(cat => !cat.name.startsWith('All '))
          .map(cat => cat.name)
          .join('/'),
      }))
      const eventParams_purchase = {
        event_category: 'Checkout',
        event_label: 'Complete Purchase',
        transaction_id: paymentData.id,
        affiliation: 'The Mattress Warehouse',
        value: paymentData.total,
        currency: 'ZAR',
        items: itemsStruct,
      }
      if (process.env.GATSBY_GTAG_DEBUG == 'true')
        console.log(
          'gtag event: purchase -> eventParams = ',
          eventParams_purchase,
        )
      window.gtag('event', 'purchase', eventParams_purchase)
      const eventParams_conversion = {
        send_to: `${process.env.GATSBY_GTAG_ACCOUNT_ID}/${process.env.GATSBY_GTAG_ACCOUNT_ID_CONVERSION_DEST}`,
        value: paymentData.total,
        currency: 'ZAR',
        transaction_id: paymentData.id,
      }
      if (process.env.GATSBY_GTAG_DEBUG == 'true')
        console.log(
          'gtag event: conversion -> eventParams = ',
          eventParams_conversion,
        )
      window.gtag('event', 'conversion', eventParams_conversion)

      const eventParams_survey = {
        // REQUIRED FIELDS
        merchant_id: process.env.GATSBY_GOOGLE_MERCHANT_CENTER_ID,
        order_id: paymentData.id,
        email: paymentData.billing.email,
        delivery_country: 'ZA',
        estimated_delivery_date: format(addMonths(new Date(), 1), 'yyyy-MM-dd'),
      }

      window.gapi.load('surveyoptin', function() {
        window.gapi.surveyoptin.render(eventParams_survey)
      })
      console.log(
        'gapi event: surveyoptin -> eventParams_survey = ',
        eventParams_survey,
      )
    }
  } catch (err) {
    if (process.env.GATSBY_GTAG_DEBUG == 'true')
      console.log(
        'gtag event: Could not run Google Analytics function "Purchase Event".',
        err,
      )
  }
}

// GOOGLE ANALYTICS --- Register Event
export const analyticsRegister = () => {
  try {
    if (isBrowser() && window.gtag) {
      const eventParams = {
        event_category: 'Account',
        event_label: 'Register',
      }
      if (process.env.GATSBY_GTAG_DEBUG == 'true')
        console.log('gtag event: sign_up -> eventParams = ', eventParams)
      window.gtag('event', 'sign_up', eventParams)
    }
  } catch {
    if (process.env.GATSBY_GTAG_DEBUG == 'true')
      console.log(
        'gtag event: Could not run Google Analytics function "Register Event".',
      )
  }
}

// GOOGLE ANALYTICS --- Login Event
export const analyticsLogin = () => {
  try {
    if (isBrowser() && window.gtag) {
      const eventParams = {
        event_category: 'Account',
        event_label: 'Login',
      }
      if (process.env.GATSBY_GTAG_DEBUG == 'true')
        console.log('gtag event: login -> eventParams = ', eventParams)
      window.gtag('event', 'login')
    }
  } catch {
    if (process.env.GATSBY_GTAG_DEBUG == 'true')
      console.log(
        'gtag event: Could not run Google Analytics function "Login Event".',
      )
  }
}

// GOOGLE ANALYTICS --- Logout Event
export const analyticsLogout = () => {
  try {
    if (isBrowser() && window.gtag) {
      const eventParams = {
        event_category: 'Account',
        event_label: 'Logout',
      }
      if (process.env.GATSBY_GTAG_DEBUG == 'true')
        console.log(
          'gtag event: logout [custom] -> eventParams = ',
          eventParams,
        )
      window.gtag('event', 'logout', eventParams)
    }
  } catch {
    if (process.env.GATSBY_GTAG_DEBUG == 'true')
      console.log(
        'gtag event: Could not run Google Analytics function "Logout Event".',
      )
  }
}

// GOOGLE ANALYTICS --- Update Account Event
export const analyticsUpdateAccount = () => {
  try {
    if (isBrowser() && window.gtag) {
      const eventParams = {
        event_category: 'Account',
        event_label: 'Update account information',
      }
      if (process.env.GATSBY_GTAG_DEBUG == 'true')
        console.log(
          'gtag event: update_account [custom] -> eventParams = ',
          eventParams,
        )
      window.gtag('event', 'update_account')
    }
  } catch {
    if (process.env.GATSBY_GTAG_DEBUG == 'true')
      console.log(
        'gtag event: Could not run Google Analytics function "Update Account Event".',
      )
  }
}
