// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-about-our-story-js": () => import("./../../../src/pages/about/our-story.js" /* webpackChunkName: "component---src-pages-about-our-story-js" */),
  "component---src-pages-about-our-team-js": () => import("./../../../src/pages/about/our-team.js" /* webpackChunkName: "component---src-pages-about-our-team-js" */),
  "component---src-pages-about-philanthropy-js": () => import("./../../../src/pages/about/philanthropy.js" /* webpackChunkName: "component---src-pages-about-philanthropy-js" */),
  "component---src-pages-about-reviews-js": () => import("./../../../src/pages/about/reviews.js" /* webpackChunkName: "component---src-pages-about-reviews-js" */),
  "component---src-pages-autres-js": () => import("./../../../src/pages/autres.js" /* webpackChunkName: "component---src-pages-autres-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-compare-mattresses-js": () => import("./../../../src/pages/compare-mattresses.js" /* webpackChunkName: "component---src-pages-compare-mattresses-js" */),
  "component---src-pages-compare-our-active-mattresses-js": () => import("./../../../src/pages/compare-our-active-mattresses.js" /* webpackChunkName: "component---src-pages-compare-our-active-mattresses-js" */),
  "component---src-pages-compare-our-b-series-mattresses-js": () => import("./../../../src/pages/compare-our-b-series-mattresses.js" /* webpackChunkName: "component---src-pages-compare-our-b-series-mattresses-js" */),
  "component---src-pages-compare-our-premium-series-mattresses-js": () => import("./../../../src/pages/compare-our-premium-series-mattresses.js" /* webpackChunkName: "component---src-pages-compare-our-premium-series-mattresses-js" */),
  "component---src-pages-contact-us-contact-request-received-js": () => import("./../../../src/pages/contact-us/contact-request-received.js" /* webpackChunkName: "component---src-pages-contact-us-contact-request-received-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-order-success-js": () => import("./../../../src/pages/order-success.js" /* webpackChunkName: "component---src-pages-order-success-js" */),
  "component---src-pages-profile-js": () => import("./../../../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-store-js": () => import("./../../../src/pages/store.js" /* webpackChunkName: "component---src-pages-store-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-templates-product-page-js": () => import("./../../../src/templates/ProductPage.js" /* webpackChunkName: "component---src-templates-product-page-js" */),
  "component---src-templates-word-press-blog-js": () => import("./../../../src/templates/WordPressBlog.js" /* webpackChunkName: "component---src-templates-word-press-blog-js" */),
  "component---src-templates-word-press-category-js": () => import("./../../../src/templates/WordPressCategory.js" /* webpackChunkName: "component---src-templates-word-press-category-js" */),
  "component---src-templates-word-press-post-js": () => import("./../../../src/templates/WordPressPost.js" /* webpackChunkName: "component---src-templates-word-press-post-js" */)
}

